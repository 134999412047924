import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link as MUILink } from '@mui/material';
import Navbar from './Navbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { snackbarError } from "./helpers/snackbarErr";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const theme = createTheme();

export default function SignUp() {
    const [errorStatus, setErrorStatus] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    let navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let email = data.get('email');
        let password = data.get('password');

        try {
            if ((email.length === 0) || (password.length === 0)) {
                throw Error("Cannot register: Missing password or email!")
            } else if (!email.includes('@')) {
                throw Error("Invalid Email Format!");
            };

            await axios.post(`${process.env.REACT_APP_METALENZ_API_HOST}/api/auth/register`, { email, password });
            enqueueSnackbar("Register successful!", {
                variant: "success"
            });
            navigate("/artifacts", { replace: true });
            setTimeout(closeSnackbar, 2000);
        } catch (err) {
            let errMessage = "" || snackbarError(err);
            enqueueSnackbar(errMessage, {
                variant: "error"
            });
            setErrorStatus(true);
            setTimeout(closeSnackbar, 2000);
        }

    };

    return (
        <ThemeProvider theme={theme}>
            <Navbar loginStatus={false} />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    error={errorStatus}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                    error={errorStatus}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    error={errorStatus}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    error={errorStatus}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <MUILink to="/login" component={Link} variant="body2">
                                    Already have an account? Sign in
                                </MUILink>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}