import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ForgotPassword from "./ForgotPassword";
import InstallerPage from "./InstallerPage";
import Login from "./Login";
import React from "react";
import Register from "./Register";
import UserPage from "./UserPage";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/userpage" element={<UserPage />} />
                <Route path="/artifacts" element={<InstallerPage />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router