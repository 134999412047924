import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './Navbar'
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { snackbarError } from "./helpers/snackbarErr";
import { useSnackbar } from 'notistack';

const theme = createTheme();

const artifactMapping = {};

const InstallerPage = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [retrievedList, setList] = useState(false);

    const getArtifactTypes = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_METALENZ_API_HOST}/api/artifacts`);
            return res.data;
        } catch (error) {
            let errMessage = "" || snackbarError(error);
            enqueueSnackbar(errMessage, {
                variant: "error"
            });
            setTimeout(closeSnackbar, 2000);
        }
    }
    const getArtifacts = async (artifactType) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_METALENZ_API_HOST}/api/artifacts/${artifactType}`);
            return res.data;
        } catch (error) {
            let errMessage = "" || snackbarError(error);
            enqueueSnackbar(errMessage, {
                variant: "error"
            });
            setTimeout(closeSnackbar, 2000);
        }
    }
    let runOnce = false
    useEffect(() => {
        async function getList() {
            try {
                let artifactTypes = await getArtifactTypes();
                for (const artifactType of artifactTypes) {
                    if (!artifactType.includes("/")) {
                        artifactMapping[artifactType] = await getArtifacts(artifactType)
                    }
                }
                console.log(artifactMapping);
                setList();
            } catch (error) {
                let errMessage = "" || snackbarError(error);
                enqueueSnackbar(errMessage, {
                    variant: "error"
                });
                setTimeout(closeSnackbar, 2000);
            }
        }
        if (!runOnce) {
            getList();
        }
        runOnce = true
    })

    const getArtifactFile = async (artifact, name) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_METALENZ_API_HOST}/api/artifacts/${artifact}/${name}/download`);
            enqueueSnackbar("Installer downloading..", {
                variant: "success"
            });
            const downloadLink = document.createElement("a");
            downloadLink.download = `{artifact}_${name}`;
            downloadLink.href = res.data;
            downloadLink.click();
            setTimeout(closeSnackbar, 4000);
        } catch (error) {
            let errMessage = "" || snackbarError(error);
            enqueueSnackbar(errMessage, {
                variant: "error"
            });
            setTimeout(closeSnackbar, 4000);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navbar loginStatus={true} />
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 6,
                        pb: 2,
                    }}
                />

                {Object.keys(artifactMapping).map((artifactType) =>
                (
                    <Container>
                    <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 1,
                        pb: 6,
                    }}>
                        <Typography
                                component="h4"
                                variant="h4"
                                align="left"
                                color="text.primary"
                                gutterBottom
                                sx={{ font: "Montserrat", "text-transform": "capitalize" }}
                            >
                                {artifactType.replaceAll("-", " ")}
                            </Typography>
                            {artifactMapping[artifactType].map((artifact)=>(
                                <Card
                                    sx={{ height: '100%', display: 'flex', flexDirection: 'column', textAlign: 'center' }}
                                >
                                    <CardActions sx={{ justifyContent: "left" }}>
                                        <Button size="small" onClick={() => { getArtifactFile(artifactType, artifact) }}>{artifact}</Button>
                                    </CardActions>
                                </Card>
                            ))}

                    </Box>
                    </Container>
                ))}
            </main>
        </ThemeProvider>
    );
}

export default InstallerPage