import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Navbar from './Navbar'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { snackbarError } from "./helpers/snackbarErr";
import { useSnackbar } from 'notistack';

const theme = createTheme();

let devicesList = {};

const UserPage = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [retrievedList, setList] = useState(false);

    const getDevices = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_METALENZ_API_HOST}/api/devices/list`);
            return res.data;
        } catch (error) {
            let errMessage = "" || snackbarError(error);
            enqueueSnackbar(errMessage, {
                variant: "error"
            });
            setTimeout(closeSnackbar, 2000);
        }
    }

    useEffect(() => {
        async function getList() {
            try {
                const res = await getDevices();
                devicesList = {};
                res.map((device) => {
                    if (!devicesList.hasOwnProperty(device.device_id)) {
                        devicesList[device.device_id] = {};
                    }
                    if (!devicesList[device.device_id].hasOwnProperty(device.model_type)) {
                        devicesList[device.device_id][device.model_type] = [];
                    }
                    devicesList[device.device_id][device.model_type].push(device);
                });

                setList(true);
            } catch (error) {
                let errMessage = "" || snackbarError(error);
                enqueueSnackbar(errMessage, {
                    variant: "error"
                });
                setTimeout(closeSnackbar, 2000);
            }
        }
        getList();
    })

    const getModelFile = async (device_id, model_type, version) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_METALENZ_API_HOST}/api/devices/${device_id}/${model_type}/${version}/model`);
            enqueueSnackbar("Model downloading..", {
                variant: "success"
            });
            const downloadLink = document.createElement("a");
            downloadLink.download = `${device_id}_${version}`;
            downloadLink.href = res.data.link;
            downloadLink.click();
            setTimeout(closeSnackbar, 4000);
        } catch (error) {
            let errMessage = "" || snackbarError(error);
            enqueueSnackbar(errMessage, {
                variant: "error"
            });
            setTimeout(closeSnackbar, 4000);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navbar loginStatus={true} />
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                            sx={{ font: "Montserrat" }}
                        >
                            Devices
                        </Typography>
                        <Stack
                            sx={{ pt: 1 }}
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                        >
                        </Stack>
                    </Container>
                </Box>
                <Container sx={{ py: 8 }} maxWidth="md">
                        {
                            Object.keys(devicesList).map((device_id) => (
                                <div key={`${device_id}`}>
                                    <h2>Device: {device_id}</h2>
                                    {Object.keys(devicesList[device_id]).map((model_type) => (
                                        <div key={`${model_type}`}>
                                            <h3>{model_type} model</h3>
                                            <Grid key={`${model_type}-grid`} container spacing={4}>
                                                {
                                                    devicesList[device_id][model_type].map((device) => (
                                                        <Grid item key={`${device.id}-${device.version}-${model_type}`} xs={12} sm={6} md={4}>
                                                            <Card
                                                                sx={{ height: '100%', display: 'flex', flexDirection: 'column', textAlign: 'center' }}
                                                            >
                                                                <CardContent sx={{ flexGrow: 1 }}>
                                                                    <Typography gutterBottom variant="subtitle" component="h3">
                                                                        Device ID: {device.device_id}
                                                                    </Typography>
                                                                    <Typography>
                                                                        Device Version: {device.name}
                                                                    </Typography>
                                                                </CardContent>
                                                                <CardActions sx={{ justifyContent: "center" }}>
                                                                    <Button size="small" onClick={() => { getModelFile(device.device_id, device.model_type, device.name) }}>Model download</Button>
                                                                </CardActions>
                                                            </Card>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </div>
                                    ))}
                                </div>
                            ))
                        }
                </Container>
            </main>
        </ThemeProvider>
    );
}

export default UserPage