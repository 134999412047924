import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { snackbarError } from "./helpers/snackbarErr";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';

import Navbar from './Navbar';

const theme = createTheme();

export default function SetPassword() {
    const [errorStatus, setErrorStatus] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();

    let navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let password = data.get('password');
        let confirmPassword = data.get('confirmPassword');
        const currentParams = Object.fromEntries([...searchParams])
        console.log(currentParams);
        let token = currentParams.token;

        try {
            if (password !== confirmPassword) {
                throw Error("Passwords do not match");
            };

            await axios.post(`${process.env.REACT_APP_METALENZ_API_HOST}/api/auth/password?token=${token}`, { password });
            enqueueSnackbar("Password change successful!", {
                variant: "success"
            });
            navigate("/login", { replace: true });
            setTimeout(closeSnackbar, 2000);
        } catch (err) {
            let errMessage = "" || snackbarError(err);
            enqueueSnackbar(errMessage, {
                variant: "error"
            });
            setErrorStatus(true);
            setTimeout(closeSnackbar, 2000);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Navbar loginStatus={false} />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Set Password
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    error={errorStatus}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    autoComplete="new-password"
                                    error={errorStatus}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}